<template>
    <div class="paypage-view-wrapper">
        <Breadcrumbs :active="2"></Breadcrumbs>
        <PayPageForm :amount="basketTotalAmount"></PayPageForm>
    </div>
</template>

<script>
    import Breadcrumbs from "@/components/Breadcrumbs";
    import PayPageForm from '@/components/payment-methods/PayPageForm.vue'    
    import {pageInfoMixin} from "@/mixins/pageInfoMixin"

    export default {
        name: "PayPage",
        components: {
            PayPageForm,
            Breadcrumbs
        },
        mixins: [pageInfoMixin],
    }
</script>

<style lang="scss" scoped>

</style>