<template>
    <div class="pa-1 d-flex flex-column paypage-wrapper">
        
        <div class="mt-6 mb-4 mx-1 pa-4 d-flex flex-column">
            <span class="mb-3 align-self-center primary--text font-weight-bold text-caption" @click="fillCards()">{{$t("paypageFormTitle")}}</span>
            <span v-if="showError" class="text-center red--text font-weight-bold text-caption">{{$t("paypageError")}}</span>
            <div class="my-2 d-flex flex-column paypage-form-group">
                <span :class="{'text-caption paypage-form-label': true, 'primary--text': inputValidations.cardNumber, 'red--text': !inputValidations.cardNumber }" @click="fillCards()">{{$t("paypageCreditCardLabel")}}</span>
                <v-text-field placeholder="4200 #### #### ####" :rules="[value => (new RegExp('[0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9]')).test(value) || 'Required.']" v-mask="'#### #### #### ####'" v-model="cardNumber" height="32px" class="my-1 teet" color="primary" validate-on-blur rounded dense hide-details outlined></v-text-field>
            </div>

            <div class="my-2 d-flex flex-column paypage-form-group">
                <span class="primary--text text-caption paypage-form-label" @click="fillCards()">{{$t("paypageDateLabel")}}</span>
                <div class="d-flex align-center">
                    <div class="my-2 d-flex align-center paypage-form-group">
                        <span :class="{'text-caption paypage-form-label': true, 'primary--text': inputValidations.month, 'red--text': !inputValidations.month }">{{$t("paypageMonthLabel")}}</span>
                        <v-text-field :rules="[value => (new RegExp('[0-9][0-9]')).test(value) || 'Required.']" :placeholder="currentMonth" v-mask="'##'" v-model="month" class="ma-1" color="primary" validate-on-blur rounded dense hide-details outlined></v-text-field>
                        <v-icon color="primary">mdi-help-circle</v-icon>
                    </div>
                    <div class="my-2 px-3 d-flex align-center paypage-form-group">
                        <span :class="{'text-caption paypage-form-label': true, 'primary--text': inputValidations.year, 'red--text': !inputValidations.year }">{{$t("paypageYearLabel")}}</span>
                        <v-text-field :rules="[value => (new RegExp('[0-9][0-9][0-9][0-9]')).test(value) || value != '' || 'Required.']" :placeholder="currentYear" v-mask="'####'" v-model="year" class="ma-1" color="primary" validate-on-blur rounded dense hide-details outlined></v-text-field>
                        <v-icon color="primary">mdi-help-circle</v-icon>
                    </div>
                </div>
            </div>

            <div class="my-2 d-flex flex-column paypage-form-group cvv">
                <span :class="{'text-caption paypage-form-label': true, 'primary--text': inputValidations.cvv, 'red--text': !inputValidations.cvv }" @click="fillCards()">{{$t("paypageCVVLabel")}}</span>
                <div class="d-flex align-center">
                    <v-text-field :rules="[value => (new RegExp('[0-9][0-9][0-9]')).test(value) || 'Required.']" placeholder="123" v-mask="'###'" v-model="cvv" class="mr-1 my-1" color="primary" validate-on-blur rounded dense hide-details outlined></v-text-field>
                    <v-icon color="primary">mdi-help-circle</v-icon>
                </div>
            </div>

            <v-spacer></v-spacer>
            <div class="mt-2 align-self-center d-flex align-center paypage-actions">
                <v-btn color="primary" class="mx-2" small outlined rounded>{{$t("paypageCancel")}}</v-btn>
                <v-btn color="primary" class="mx-2" @click="validateInfo" small rounded depressed>{{$t("paypageValidate")}}</v-btn>
            </div>
        </div>
        <div class="mx-1 align-self-center">
            <p class="primary--text text-caption text-center">{{$t("paypageFooterExplain")}}</p>
            <div class="d-flex justify-space-around align-center">
                <v-img max-width="100px" src="@/assets/img/cb-secure-payment.png" contain></v-img>
                <v-img max-width="100px" src="@/assets/img/verified-visa.png" contain></v-img>
                <v-img max-width="100px" src="@/assets/img/mastercard.png" contain></v-img>
            </div>
        </div>
    </div>
</template>

<script>
    const md5 = require('md5');
    const moment = require('moment');
    import { mapActions } from 'vuex'
    export default {
        props:{
            amount: {
                type: Number
            }
        },
        data() {
            return {
                totalAmount: 0.0,
                cardNumber: "",
                month: "",
                year: "",
                cvv: "",
                currentDate: moment().format("MM-YYYY"),
                showError: false,
                inputValidations: {
                    cardNumber: true,
                    year: true,
                    month: true,
                    cvv: true
                }
            }
        },
        created() {
            if(this.$route.query && this.$route.query.customerPayment){
                this.totalAmount = this.basketTotalAmount
            }else{
                this.totalAmount = this.amount
            }
        },
        methods: {
            ...mapActions('customer', ['setCardNumber']),

            validateInfo(){

                this.showError = false

                this.inputValidations.cardNumber = (new RegExp('[0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9]')).test(this.cardNumber)

                this.inputValidations.year = this.validDate && (new RegExp('[0-9][0-9][0-9][0-9]')).test(this.year)
                this.inputValidations.month = this.validDate && (new RegExp('[0-9][0-9]')).test(this.month)

                this.inputValidations.cvv = (new RegExp('[0-9][0-9][0-9]')).test(this.cvv)

                if(this.validInfo){
                    this.setCardNumber("**** **** **** " + this.cardNumber.substr(this.cardNumber.length - 4))
                    this.$router.push({name: 'PaymentValidation', query: {customerPayment: true}})
                }else{
                    this.showError = true
                }
            },
            fillCards(){
                let nextDate = moment(this.currentDate, "MM-YYYY").add(1, 'M').format('MM-YYYY')
                this.cardNumber = "4200 0000 0000 0000"
                this.month = nextDate.split("-")[0]
                this.year = nextDate.split("-")[1]
                this.cvv = "123"
            },
            getCardNumberHash() {
              let cardArray = this.cardNumber.split(" ");
              cardArray[1] = cardArray[1][0] + cardArray[1][1] + "00";
              cardArray[2] = "0000";
              return md5(cardArray.join(""))
            }
        },
        computed: {
            currentYear(){
                return this.currentDate.split("-")[1]
            },
            currentMonth(){
                return this.currentDate.split("-")[0]
            },
            validDate(){
                return moment(`${this.month}-${this.year}`, 'MM-YYYY').isAfter(moment(this.currentDate, "MM-YYYY"))
            },
            validInfo(){
                return this.inputValidations.cardNumber && this.inputValidations.year && this.inputValidations.month && this.inputValidations.cvv
            }
        }
    }
</script>

<style>
    .paypage-form{
        border: solid 1px var(--color-primary);
    }
    .paypage-form-group .v-text-field .v-input__control .v-input__slot {
        min-height: auto !important;
        display: flex !important;
        align-items: center !important;
        padding: 0 8px;
        font-size: 0.9rem;
    }
    .paypage-form-group.cvv{
        width: 100px;
    }
</style>
